import React from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import LayoutContent from '../layoutContent'

const LayoutWrapper = styled.div`
  div:first-child {
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      padding: 0;
    }
  }
`

const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 80px;
  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 2;
    margin-top: 40px;
    grid-row-gap: 20px;
  }
`
const MediaContainer = styled.div`
  grid-column: ${props => (props.reverse ? '2' : '1')} / span 1;
  grid-row: 1 / span 1;

  img {
    object-fit: contain;
  }

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    grid-column: 1;
    grid-row: ${props => (props.reverse ? '2' : '1')} / span 1;
  }
`

const DesktopMedia = styled.img`
  display: block;
  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    display: none;
  }
`
const MobileMedia = styled.img`
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    display: block;
  }
`

const TextContainer = styled.div`
  grid-column: ${props => (props.reverse ? '1' : '2')} / span 1;
  grid-row: 1 / span 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: ${props => (props.reverse ? '0 0 0 80px' : '0 80px 0 0')};

  ${Heading} p {
    margin-bottom: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    grid-column: 1;
    grid-row: ${props => (props.reverse ? '1' : '2')} / span 1;
    padding: ${props => props.theme.spacing.medium};
  }
`

export const SecondaryMediaSegment = ({
  mediaUrl,
  mobileUrl,
  header,
  body,
  ctaText,
  ctaTarget,
  reverse,
}) => {
  return (
    <LayoutWrapper>
      <LayoutContent>
        <LayoutGrid>
          <MediaContainer reverse={reverse}>
            <DesktopMedia src={mediaUrl} />
            <MobileMedia src={mobileUrl} />
          </MediaContainer>
          <TextContainer reverse={reverse}>
            <Heading
              headingSize={3}
              dangerouslySetInnerHTML={{
                __html: header.childMarkdownRemark.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            ></div>
          </TextContainer>
        </LayoutGrid>
      </LayoutContent>
    </LayoutWrapper>
  )
}
