import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Check } from '../../images/feature-check.svg'
import { ReactComponent as Thinking } from '../../images/smiley-thinking.svg'

const FeatureTable = styled.table`
  && {
    background-color: white;
    padding: 0;
    height: 1px;
    margin-top: 40px;
    border-spacing: 0;
  }

  thead {
    font-size: 12px;

    tr {
      th {
        background-color: rgb(247, 206, 205);
      }
      th:first-child {
        padding: 24px 40px;
        border-radius: 20px 0 0 0;
      }
      th:last-child {
        border-radius: 0 20px 0 0;
      }
      th:nth-child(2) {
        background-color: rgb(244, 185, 184);
      }
      th:not(:first-child) {
        padding: 24px 0;
        vertical-align: middle;
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      font-size: 14px;

      td:first-child {
        padding: 24px 40px;
      }

      td:nth-child(2) {
        background-color: rgb(244, 185, 184);
      }

      td {
        height: 100%;
        padding: 0;
        background-color: rgb(255, 233, 232);
      }
    }

    tr:not(:last-child) td {
      border-bottom: 1px solid rgb(255, 182, 182);
    }

    tr td:not(:first-child) {
      vertical-align: middle;
      padding: 0;
    }

    tr:last-child {
      td:first-child {
        border-radius: 0 0 0 20px;
      }
      td:last-child {
        border-radius: 0 0 20px 0;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    && {
      border-spacing: 0;
      margin-top: 0;
    }

    thead {
      font-size: 12px;

      tr {
        th {
          background-color: rgb(247, 206, 205);
        }
        th:first-child {
          text-align: left;
          padding: 4px 16px;
          border-radius: 0;
        }
        th:last-child {
          border-radius: 0;
        }
        th:nth-child(2) {
          background-color: rgb(244, 185, 184);
        }
        th:not(:first-child) {
          padding: 0;
          vertical-align: middle;
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        font-size: 12px;

        td:first-child {
          padding: 8px 16px;
        }

        td:nth-child(2) {
          background-color: rgb(244, 185, 184);
        }

        td {
          height: 100%;
          padding: 0;
          background-color: rgb(255, 233, 232);
        }
      }

      tr:not(:last-child) td {
        border-bottom: 1px solid rgb(255, 182, 182);
      }

      tr td:not(:first-child) {
        vertical-align: middle;
        padding: 0;
      }

      tr:last-child {
        td:first-child {
          border-radius: 0;
        }
        td:last-child {
          border-radius: 0;
        }
      }
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  svg {
    height: 32px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    padding: 8px 8px;
  }
`

export const ComparisonFeature = ({ config }) => {
  return (
    <FeatureTable>
      <thead>
        <tr>
          {config.headerRow.map((e, i) => (
            <th key={`header-` + i}>{e}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {config.rows.map((e, i) => (
          <tr key={`feature-` + i}>
            <td>{e}</td>
            <td>
              <IconContainer>
                <Check />
              </IconContainer>
            </td>
            <td>
              <IconContainer>
                <Thinking />
              </IconContainer>
            </td>
          </tr>
        ))}
      </tbody>
    </FeatureTable>
  )
}
