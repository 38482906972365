import React from 'react'
import styled from 'styled-components'
import Button from './buttons/button'
import SmallText from './typography/smallText'

const FormWrapper = styled.div`
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  h3 {
    font-weight: normal;
  }
`

const Legend = styled.legend`
  margin: 0 0 1em;
`

const SubmitWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  text-align: flex-start;
`

const TextContainer = styled.div`
  margin: 1em 0;
`

// prettier-ignore
const StyledInput = styled.input(({ theme }) => `
  width: 100%;
  background: ${theme.colors.fieldBackgroundColor};
  border: 1px solid ${theme.colors.greyLight};
  border-radius: 100px;
  padding: ${theme.spacing.small} ${theme.spacing.medium};

  ::placeholder {
    color: ${theme.colors.grey};
  }
`)

const MailingListForm = () => {
  return (
    <FormWrapper>
      <form
        className="lianamailer"
        name="mailingList"
        data-lianamailer="form"
        method="post"
        action="https://uutiskirje.alexandria.fi/account"
      >
        <input type="hidden" name="join" value="951519" />
        <Legend>Sähköposti *</Legend>
        <StyledInput
          name="email"
          type="text"
          placeholder="nimi@domain"
          required={true}
        />
        <div
          aria-hidden="true"
          style={{ position: 'absolute', left: '-7777px' }}
        >
          <input type="text" name="lm-gtfo" tabIndex="-1" />
        </div>
        <TextContainer>
          <SmallText>
            Voit milloin vain lopettaa uutiskirjeen tilauksen ilmoittamalla
            siitä meille.
          </SmallText>
          <SmallText>
            Täyttämällä lomakkeen annan Alexandrialle suostumukseni tietojeni
            käsittelyyn Alexandrian tietojenkäsittelyperiaatteen mukaisesti.
            Alexandrian tietojenkäsittelyperiaatteet (
            <a href="/yleista/tietosuoja/">
              https://www.alexandria.fi/yleista/tietosuoja/
            </a>
            )
          </SmallText>
        </TextContainer>
        <SubmitWrapper>
          <Button type="submit">Tilaa</Button>
        </SubmitWrapper>
      </form>
    </FormWrapper>
  )
}

export default MailingListForm
