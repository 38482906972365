import React from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import LayoutContent from '../layoutContent'

const LayoutGrid = styled.div`
  display: grid;
  grid-auto-rows: min-content;
`

const Background = styled.div`
  grid-row: 3 / span 3;
  grid-column: 1;
  background-color: rgb(255, 233, 232);
`

const VideoContainer = styled.div`
  grid-row: 1 / span 3;
  grid-column: 1;

  display: flex;
  justify-content: center;

  video {
    width: 100%;
    @media (min-width: ${p => p.theme.breakpoints.md.min}) {
      width: ${p => p.theme.width.content};
    }
    @media (max-width: ${p => p.theme.breakpoints.sm.max}) {
      margin: 0 20px;
    }
    object-fit: contain;
    border-radius: 20px;
  }

  video[poster] {
    object-fit: cover;
  }
`

const TextGridElement = styled.div`
  grid-row: 4 / span 2;
  grid-column: 1;
  height: auto;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    max-width: ${props => props.theme.width.contentInside};
  }
`

const CustomLayoutContent = styled(LayoutContent)`
  margin-top: ${p => p.theme.spacing.medium};
`

export const MainMediaSegment = ({ mediaUrl, header, body, poster }) => {
  return (
    <LayoutGrid id="main-media">
      <Background />
      <VideoContainer>
        <video src={mediaUrl} controls poster={poster} />
      </VideoContainer>
      <TextGridElement>
        <CustomLayoutContent>
          <TextContainer>
            <Heading headingSize={3}>{header}</Heading>
            <div
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            ></div>
          </TextContainer>
        </CustomLayoutContent>
      </TextGridElement>
    </LayoutGrid>
  )
}
