import React from 'react'
import styled from 'styled-components'
import LayoutContent from '../layoutContent'
import { Heading } from '../typography/headings'
import Button from '../buttons/button'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.spacing.medium} 0
    ${props => props.theme.spacing.medium} 0;

  ${Heading} {
    text-align: center;
  }

  p {
    text-align: center;
    max-width: ${props => props.theme.width.contentInside};
  }

  && a {
    text-decoration: none;
  }
`

export const CampaignCTASegment = ({ heading, ctaText, ctaTarget, body }) => (
  <LayoutContent>
    <Wrapper>
      <Heading headingSize={3}>{heading}</Heading>
      <div
        dangerouslySetInnerHTML={{
          __html: body.childMarkdownRemark.html,
        }}
      ></div>
      <Button as={Link} to={ctaTarget} large={'true'}>
        {ctaText}
      </Button>
    </Wrapper>
  </LayoutContent>
)
