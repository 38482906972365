import React from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import { ComparisonFeature } from './comparisonFeature'

const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    margin-bottom: 40px;
  }
`

const TextContainer = styled.div`
  grid-column: 2 / span 3;

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    grid-column: 1 / span 1;
    grid-row: 1;
    padding: 80px 20px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const FeatureContainer = styled.div`
  grid-column: 6 / span 6;

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    grid-column: 1 / span 1;
    grid-row: 2;
  }
`

export const FeatureSegment = ({ config, header, body }) => {
  const configData = JSON.parse(config)

  return (
    <LayoutGrid>
      <TextContainer>
        <Heading headingSize={4}>{header}</Heading>
        <div
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}
        ></div>
      </TextContainer>
      <FeatureContainer>
        <ComparisonFeature config={configData} />
      </FeatureContainer>
    </LayoutGrid>
  )
}
